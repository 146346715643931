import React from "react";
import type { FormEvent } from "react";
import { Navigate } from "react-router-dom";
import {
  Container,
  Title,
  Paper,
  TextInput,
  PasswordInput,
  Text,
  Button,
} from "@mantine/core";
import { useForm, isEmail, hasLength } from "@mantine/form";

import { signInWithEmail } from "../../services/auth";
import { useUserContext } from "../../context/user";

interface FormValues {
  email: string;
  password: string;
}

export function SignIn() {
  const [error, setError] = React.useState<string | undefined>();

  const user = useUserContext();

  const form = useForm<FormValues>({
    initialValues: { email: "", password: "" },
    validate: {
      email: isEmail("E-mail inválido"),
      password: hasLength({ min: 8 }, "A senha contém pelo menos 8 caracteres"),
    },
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    const { email, password } = form.values;

    const { error: newError } = await signInWithEmail(email, password);

    setError(newError?.message);
  };

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container size={420} my={40}>
      <Title align="center" color="yellow">vila</Title>
      <Paper withBorder shadow="md" p={30} mt={30} radius="sm">
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Email"
            placeholder="seu@email.com"
            autoFocus
            {...form.getInputProps("email")}
          />

          <PasswordInput
            label="Senha"
            placeholder="Senha"
            mt="md"
            {...form.getInputProps("password")}
          />
          {form.errors.password && <Text>{form.errors.password}</Text>}

          <Button fullWidth mt="xl" type="submit" disabled={!form.isValid()}>
            Entrar
          </Button>

          {error && (
            <Text color="red" size="xs" align="center" mt="md">
              Usuário e senha não correspondem
            </Text>
          )}
        </form>
      </Paper>
    </Container>
  );
}

export default SignIn;
