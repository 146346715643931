import { TransactionNormalized } from "services/transactions";

export const calculateTotal = (transactions: TransactionNormalized[]) => {
  return transactions.reduce((acc: number, transaction) => {
    if (transaction.type === "income") {
      return acc + transaction.amount;
    }

    return acc - transaction.amount;
  }, 0);
};
