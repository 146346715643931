import type { AuthError, AuthResponse, UserResponse } from "@supabase/supabase-js";

import { supabase } from "../utils/supabase";

export async function signUpWithEmail(
  email: string,
  password: string
): Promise<AuthResponse> {
  return await supabase.auth.signUp({ email, password });
}

export async function signInWithEmail(
  email: string,
  password: string
): Promise<AuthResponse> {
  return await supabase.auth.signInWithPassword({
    email,
    password,
  });
}

export async function signOut(): Promise<{ error: AuthError | null }> {
  return await supabase.auth.signOut();
}

export async function changePassword(password: string) {
  return await supabase.auth.updateUser({ password });
}
