import React from 'react';

import { Select as SelectBase } from '@mantine/core';
import type { SelectProps as SelectBaseProps } from '@mantine/core';

import { useController } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';

export interface SelectProps
  extends Omit<SelectBaseProps, 'defaultValue' | 'name'>,
    UseControllerProps {}

function Select(props: SelectProps, ref: any) {
  const { field } = useController(props);

  return <SelectBase {...props} {...field} ref={ref} />;
}

export default React.forwardRef(Select);
