import React from 'react'
import type { FormEvent } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  Container,
  Title,
  Paper,
  PasswordInput,
  Text,
  Button
} from '@mantine/core'
import { useForm, hasLength } from '@mantine/form'
import { showNotification } from '@mantine/notifications'

import { changePassword } from '../../services/auth'
import { useUserContext } from '../../context/user'
import { IconX } from '@tabler/icons'

interface FormValues {
  password: string
  confirmation: string
}

export function ChangePassword () {
  const user = useUserContext()
  const navigate = useNavigate()

  const form = useForm<FormValues>({
    initialValues: { password: '', confirmation: '' },
    validate: {
      password: hasLength({ min: 8 }, 'A senha contém pelo menos 8 caracteres')
    }
  })

  const { password, confirmation } = form.values

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (password !== confirmation) {
      showNotification({
        title: 'Ops!',
        message: 'As senhas devem ser iguais',
        color: 'red',
        icon: <IconX size={18} />
      })

      return
    }

    try {
      await changePassword(password)

      navigate('/')
    } catch (error) {
      showNotification({
        title: 'Ops!',
        message: 'As senhas devem ser iguais',
        color: 'red',
        icon: <IconX size={18} />
      })
    }
  }

  const shouldShowErrorMessage =
    password.length > 0 && confirmation.length > 0 && password !== confirmation

  if (!user) {
    return <Navigate to='/' replace />
  }

  return (
    <Container size={420} my={40}>
      <Title align='center' color='yellow'>
        vila
      </Title>
      <Paper withBorder shadow='md' p={30} mt={30} radius='sm'>
        <form onSubmit={handleSubmit}>
          <PasswordInput
            label='Senha'
            placeholder='Insira sua nova senha'
            autoFocus
            {...form.getInputProps('password')}
          />

          <PasswordInput
            label='Confimaçãp'
            placeholder='Confirme sua senha'
            mt='md'
            type='password'
            {...form.getInputProps('confirmation')}
          />
          {shouldShowErrorMessage && <Text color="red" size="xs">As senhas devem ser iguais</Text>}

          <Button fullWidth mt='xl' type='submit' disabled={!form.isValid()}>
            Alterar senha
          </Button>
        </form>
      </Paper>
    </Container>
  )
}

export default ChangePassword
