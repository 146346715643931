import { Link, useLocation } from "react-router-dom";
import { ActionIcon } from "@mantine/core";
import {
  IconHome,
  IconArrowsDownUp,
  IconApps,
  IconCreditCard,
} from "@tabler/icons";

import { useStyles } from "./Footer.styles";

const Footer = () => {
  const location = useLocation();
  const { classes } = useStyles();

  return (
    <footer className={classes.base}>
      <ActionIcon
        component={Link}
        to="/"
        radius="xl"
        color={location.pathname === "/" ? "indigo" : "dark"}
        variant={location.pathname === "/" ? "light" : "subtle"}
        p={8}
        size="xl"
      >
        <IconHome />
      </ActionIcon>
      <ActionIcon
        component={Link}
        to="/transacoes"
        radius="xl"
        color={location.pathname === "/transacoes" ? "indigo" : "dark"}
        variant={location.pathname === "/transacoes" ? "light" : "subtle"}
        p={8}
        size="xl"
      >
        <IconArrowsDownUp />
      </ActionIcon>
      <ActionIcon
        component={Link}
        to="/categorias"
        radius="xl"
        color={location.pathname === "/categorias" ? "indigo" : "dark"}
        variant={location.pathname === "/categorias" ? "light" : "subtle"}
        p={8}
        size="xl"
      >
        <IconApps />
      </ActionIcon>
      <ActionIcon
        component={Link}
        to="/metodos"
        radius="xl"
        color={location.pathname === "/metodos" ? "indigo" : "dark"}
        variant={location.pathname === "/metodos" ? "light" : "subtle"}
        p={8}
        size="xl"
      >
        <IconCreditCard />
      </ActionIcon>
    </footer>
  );
};

export default Footer;
