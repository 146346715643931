import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

import { TransactionType } from "../../services/categories";

import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";

export const useStyles = createStyles(
  (theme: MantineTheme, params: { type: TransactionType }) => {
    const color = TRANSACTION_TYPE_DICTIONARY[params.type].color;

    return {
      base: {
        minWidth: 320
        // borderColor: theme.colors[color][6],
      },
    };
  }
);
