import React from "react";
import { ActionIcon, Button, Group, Overlay, Stack, Text } from "@mantine/core";
import { IconArrowLeft, IconPlus } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

import { TransactionType } from "../../services/categories";
import { useStyles } from "./AddFloatingButton.styles";
import { useScrollLock, useToggle } from "@mantine/hooks";

const AddFloatingButton = () => {
  const [isOpen, toggleisOpen] = useToggle([false, true] as const);

  useScrollLock(isOpen);

  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <>
      {isOpen && (
        <>
          <Stack
            style={{
              position: "fixed",
              bottom: 90,
              zIndex: 2,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Button
              radius="lg"
              color="green"
              component={Link}
              to="/adicionar/income"
            >
              Nova receita
            </Button>
            <Button
              radius="lg"
              color="red"
              component={Link}
              to="/adicionar/expense"
            >
              Nova despesa
            </Button>
            <Button
              radius="lg"
              color="blue"
              component={Link}
              to="/adicionar/investment"
            >
              Novo investimento
            </Button>
            <Button
              radius="lg"
              color="orange"
              component={Link}
              to="/adicionar/loan"
            >
              Novo empréstimo
            </Button>
          </Stack>
          <Overlay
            opacity={0.6}
            color="#000"
            onClick={() => toggleisOpen()}
            blur={2}
            zIndex={1}
          />
        </>
      )}
      <ActionIcon
        size="xl"
        variant="gradient"
        onClick={() => toggleisOpen()}
        style={{
          position: "fixed",
          bottom: 38,
          left: "50%",
          transform: "translateX(-50%)",
          borderRadius: "50%",
          zIndex: 2,
        }}
      >
        <IconPlus
          size={36}
          strokeWidth={1}
          style={{
            flexShrink: 0,
            transform: isOpen ? "rotate(-45deg)" : "",
            transition: ".3s ease",
          }}
        />
      </ActionIcon>
    </>
  );
};

export default AddFloatingButton;
