import { createContext, useContext } from "react";
import type { ReactNode } from "react";
import type { User } from "@supabase/supabase-js";

interface State {
  value?: User;
}

export const StoreContext = createContext<State["value"]>(undefined);

interface UserProviderProps extends State {
  children: ReactNode;
}

export const UserProvider = ({ value, children }: UserProviderProps) => {
  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useUserContext = () => {
  const store = useContext(StoreContext);

  if (store === null) {
    throw new Error(
      "useContext(Header): context is undefined. Seems you forgot to wrap component within the Provider"
    );
  }

  return store;
};
