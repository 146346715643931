import React from "react";

import * as categoriesServices from "../services/categories";
import type { TransactionType } from "../services/categories";
import { getOptionsBySubCategories } from "utils/categories-group";

type SelectOption = { label: string; value: string; group?: string };

export const useCategories = (type: TransactionType) => {
  const [categories, setCategories] = React.useState<SelectOption[]>([]);

  React.useEffect(() => {
    const getSubCategories = async () => {
      const data = await categoriesServices.getSubCategoriesByType(type);

      if (data?.length) {
        setCategories(getOptionsBySubCategories(data));
      }
    };

    const getCategories = async () => {
      const data = await categoriesServices.getCategoriesByType(type);

      if (data?.length) {
        setCategories(
          data.map((category) => ({
            label: category.title,
            value: category.id,
          }))
        );
      }
    };

    type === "expense" ? getSubCategories() : getCategories();
  }, [type]);

  return categories;
};
