import { PostgrestResponse } from "@supabase/supabase-js";
import { supabase } from "../utils/supabase";

import type { TransactionType } from "./categories";

export interface Transaction {
  id: string;
  created_at: string;
  type: TransactionType;
  amount: number;
  date: string;
  author: string;
  category: string;
  method: string | null;
  description: string | null;
  paid_by: string;
  shared: boolean | null;
  obs: string | null;
}

interface TransactionResponse extends Omit<Transaction, "category"> {
  category: { parent_id: { title: string }; title: string };
}

export type TotalByCategory = {
  id: string;
  category: string;
  budget: number | null;
  total: number | null;
};

export interface TransactionNormalized extends Transaction {
  subcategory?: string;
}

export const addTransaction = async (
  transaction: Omit<Transaction, "id" | "created_at">
) => {
  return await supabase.from("transactions").insert(transaction);
};

export const editTransaction = async (
  transaction: Omit<Transaction, "created_at" | "type" | "author" | "paid_by">
) => {
  return await supabase
    .from("transactions")
    .update(transaction)
    .eq("id", transaction.id);
};

export const getTransaction = async (
  id: Transaction["id"]
): Promise<Transaction | null> => {
  const { data }: PostgrestResponse<Transaction> = await supabase
    .from("transactions")
    .select("*")
    .eq("id", id);

  if (!data) {
    return null;
  }

  return data[0];
};

export const getTransactions = async (
  order: keyof Transaction = "date",
  month: number
): Promise<TransactionNormalized[] | null> => {
  console.log("🚀 - month", month);
  const { data }: PostgrestResponse<TransactionResponse> = await supabase
    .from("transactions")
    .select("*, category(title, parent_id(title), budget)")
    .eq("month", month)
    .order(order, { ascending: false });

  if (!data) {
    return null;
  }

  const normalizedData = data.map((transaction) => {
    const category = transaction.category.parent_id
      ? {
          category: transaction.category.parent_id.title,
          subcategory: transaction.category.title,
        }
      : {
          category: transaction.category.title,
        };

    return { ...transaction, ...category };
  });

  return normalizedData;
};

export const getAmountByCategory = async (
  order: keyof Transaction = "date"
): Promise<TransactionNormalized[] | null> => {
  const { data }: PostgrestResponse<TransactionResponse> = await supabase
    .from("transactions")
    .select("*, category(title, parent_id(title), budget)")
    .order(order, { ascending: false });

  if (!data) {
    return null;
  }

  const normalizedData = data.map((transaction) => {
    const category = transaction.category.parent_id
      ? {
          category: transaction.category.parent_id.title,
          subcategory: transaction.category.title,
        }
      : {
          category: transaction.category.title,
        };

    return { ...transaction, ...category };
  });

  return normalizedData;
};

export const getTransactionById = async (
  id: Transaction["id"]
): Promise<Transaction[] | null> => {
  if (!id) {
    throw new Error("You need to select a transaction id");
  }

  const { data } = await supabase.from("transactions").select("*").is("id", id);

  return data;
};

export const getTransactionsByType = async (
  type: TransactionType,
  order: keyof Transaction = "date"
): Promise<TransactionNormalized[] | null> => {
  if (!type) {
    throw new Error("You need to select a transaction type");
  }

  const { data }: PostgrestResponse<TransactionResponse> = await supabase
    .from("transactions")
    .select("*, category(title, parent_id(title))")
    .order(order, { ascending: false })
    .eq("type", type);

  if (!data) {
    return null;
  }

  const normalizedData = data.map((transaction) => {
    const category =
      typeof transaction.category === "string"
        ? {
            category: transaction.category,
          }
        : {
            category: transaction.category.parent_id.title,
            subcategory: transaction.category.title,
          };

    return { ...transaction, ...category };
  });

  return normalizedData;
};

export const getTransactionsByAuthor = async (
  author: Transaction["author"]
): Promise<Transaction[] | null> => {
  if (!author) {
    throw new Error("You need to select a transaction author");
  }

  const { data } = await supabase
    .from("transactions")
    .select("*")
    .is("author", author);

  return data;
};

export const getSharedTransactions = async (): Promise<
  Transaction[] | null
> => {
  const { data } = await supabase
    .from("transactions")
    .select("*")
    .is("shared", true);

  return data;
};

export const updateTransaction = async ({
  id,
  ...category
}: Partial<Transaction>) => {
  return await supabase.from("transactions").update(category).eq("id", id);
};

export const deleteTransaction = async (id: Transaction["id"]) => {
  return await supabase.from("transactions").delete().eq("id", id);
};

export const getTransactionsByMonth = async (
  limit: number = 1000,
  month: number = new Date().getMonth() + 1
): Promise<Transaction[] | null> => {
  if (!month) {
    throw new Error("You need to select a month");
  }

  const { data } = await supabase
    .from("transactions")
    .select("*, category(title, parent_id(title), budget)")
    .eq("month", month)
    .limit(limit)
    .order("date", { ascending: false });

  if (!data) {
    return null;
  }

  const normalizedData = data.map((transaction) => {
    const category = transaction.category.parent_id
      ? {
          category: transaction.category.parent_id.title,
          subcategory: transaction.category.title,
        }
      : {
          category: transaction.category.title,
        };

    return { ...transaction, ...category };
  });

  return normalizedData;
};

export const getTotalTransactionsByMonth = async (
  month: number = new Date().getMonth() + 1
): Promise<{ type: TransactionType; total: number }[] | null> => {
  if (!month) {
    throw new Error("You need to select a month");
  }

  const { data } = await supabase.rpc("sum_month", {
    month,
  });

  return data as unknown as { type: TransactionType; total: number }[];
};

export const getTotalExpensesByCategoryAndMonth = async (
  limit: number = 1000,
  month: number = new Date().getMonth() + 1
): Promise<TotalByCategory[] | null> => {
  if (!month) {
    throw new Error("You need to select a month");
  }

  const { data } = await supabase
    .rpc("select_total_by_category_and_month", {
      month,
    })
    .limit(limit);

  return data as unknown as TotalByCategory[];
};

// export const getFilteredTransactions = async (
//   filters: Record<string, FormDataEntryValue | string>
// ): Promise<Transaction[] | null> => {
//   if (!filters) {
//     throw new Error("You need to select a filter");
//   }

//   let query = supabase
//     .from("transactions")
//     .select("*, category(title, parent_id(title), budget)");

//   for (const filter in filters) {
//     query = query.eq(filter, filters[filter]);
//   }

//   const data = await query;
//   console.log("🚀 - data", data);

//   if (!data) {
//     return null;
//   }

//   const normalizedData = data.map((transaction) => {
//     const category = transaction.category.parent_id
//       ? {
//           category: transaction.category.parent_id.title,
//           subcategory: transaction.category.title,
//         }
//       : {
//           category: transaction.category.title,
//         };

//     return { ...transaction, ...category };
//   });

//   return normalizedData;
// };

type Filters = {
  id?: string;
  created_at?: string;
  type?: string;
  amount?: string;
  date?: string;
  author?: string;
  category?: string;
  method?: string;
  description?: string;
  paid_by?: string;
  shared?: string;
  obs?: string;
  month?: string;
  year?: string;
};

type TransactionsArgs = {
  filters?: Filters;
  order?: string;
  limit?: number;
};

export const getTransactionsFinal = async (
  args: TransactionsArgs = {}
): Promise<TransactionNormalized[] | null> => {
  const { filters, order = "date", limit } = args;

  let query = supabase
    .from("transactions")
    .select(
      filters?.category
        ? "*, category!inner(*, parent_id!inner(*))"
        : "*, category!inner(*, parent_id(*))"
    );

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      query = query.eq(key, value);
    });
  }

  if (limit) {
    query = query.limit(limit);
  }

  const { data }: PostgrestResponse<TransactionResponse> = await query
    .order(order, { ascending: false })
    .order("created_at", { ascending: false });
  console.log("🚀 - data", data);

  if (!data) {
    return null;
  }

  const normalizedData = data.map((transaction) => {
    const category = transaction.category.parent_id
      ? {
          category: transaction.category.parent_id.title,
          subcategory: transaction.category.title,
        }
      : {
          category: transaction.category.title,
        };

    return { ...transaction, ...category };
  });

  return normalizedData;
};
