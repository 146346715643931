/**
 * Remove keys when propertie is -> `null`, `undefined`,
 * `empty`, and `$undefined`,
 * this used to map `css` values from stitches.
 * https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
 */
function filterFalsyValue<V extends Object>(obj: Record<string, any>): V {
  return (Object.keys(obj) as Array<keyof typeof obj>).reduce(
    (acc, key) =>
      obj[key] === undefined ||
      obj[key] === "$undefined" ||
      obj[key] === null ||
      obj[key] === "" ||
      (typeof obj[key] === "string" && obj[key].includes("undefined"))
        ? { ...acc }
        : { ...acc, [key]: obj[key] },
    {} as V
  );
}

export default filterFalsyValue;
