import { useParams } from "react-router-dom";

import { Transaction } from "components/Transaction";
import type { TransactionType } from "services/categories";

export function AddTransaction() {
  const { type = "expense" } = useParams<{ type: TransactionType }>();

  return <Transaction type={type} />;
}

export default AddTransaction;
