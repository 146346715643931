import React from "react";
import {
  Container,
  Text,
  Stack,
  Flex,
  Center,
  Loader,
  Button,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IconChevronRight, IconChevronLeft } from "@tabler/icons";

import { AddFloatingButton } from "components/AddFloatingButton";
import { CategoryProgress } from "components/CategoryProgress";
import { formatCurrency } from "utils/currency";

import type { TransactionNormalized } from "../../services/transactions";
import * as transactionsServices from "../../services/transactions";
import type { TotalByCategory } from "../../services/transactions";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { TransactionPreview } from "components/TransactionPreview";
import { TransactionType } from "services/categories";

dayjs.locale("pt-br");

const Home = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMoreCategories, setIsLoadingMoreCategories] =
    React.useState(false);
  const [isLoadingMoreTransactions, setIsLoadingMoreTransactions] =
    React.useState(false);
  const [hasMoreCategories, setHasMoreCategories] = React.useState(true);
  const [hasMoreTransactions, setHasMoreTransactions] = React.useState(true);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [transactions, setTransactions] = React.useState<
    TransactionNormalized[]
  >([]);
  const [totalByCategory, setTotalByCategory] = React.useState<
    TotalByCategory[]
  >([]);
  const [totalByType, setTotalByType] = React.useState<
    {
      type: TransactionType;
      total: number;
    }[]
  >();

  const totalIncome = React.useMemo(
    () => totalByType?.find(({ type }) => type === "income")?.total || 0,
    [totalByType]
  );

  const totalExpenses = React.useMemo(
    () => totalByType?.find(({ type }) => type === "expense")?.total || 0,
    [totalByType]
  );

  const loadMoreCategories = async () => {
    setIsLoadingMoreCategories(true);
    const data =
      await transactionsServices.getTotalExpensesByCategoryAndMonth(1000, month + 1);
    if (data) {
      setTotalByCategory(data);
    }
    setIsLoadingMoreCategories(false);
    setHasMoreCategories(false);
  };

  const loadMoreTransactions = async () => {
    setIsLoadingMoreTransactions(true);
    const data = await transactionsServices.getTransactionsFinal({
      filters: { month: (month + 1 ).toString()},
    });
    if (data) {
      setTransactions(data);
    }
    setIsLoadingMoreTransactions(false);
    setHasMoreTransactions(false);
  };

  React.useEffect(() => {
    const normalizedMonth = month + 1;
    Promise.all([
      transactionsServices.getTotalExpensesByCategoryAndMonth(
        3,
        normalizedMonth
      ),
      transactionsServices.getTransactionsFinal({
        filters: {
          month: normalizedMonth.toString(),
        },
        limit: 5,
      }),
      transactionsServices.getTotalTransactionsByMonth(normalizedMonth),
    ]).then(([transactionsData, categorisData, typeData]) => {
      if (transactionsData) {
        setTotalByCategory(transactionsData);
      }

      if (categorisData) {
        setTransactions(categorisData);
      }

      if (typeData) {
        setTotalByType(typeData);
      }

      setIsLoading(false);
    });
  }, [month]);

  if (isLoading) {
    return (
      <Center sx={{ height: "100vh" }}>
        <Loader />
      </Center>
    );
  }

  return (
    <div>
      <div
        style={{
          background: "linear-gradient(45deg, #4c6ef5 0%, #15aabf 100%)",
          paddingTop: 32,
          paddingBottom: 16,
        }}
      >
        <Container size="xs">
          <Stack align="center">
            <Carousel
              sx={{ maxWidth: 200 }}
              nextControlIcon={<IconChevronRight size={16} />}
              previousControlIcon={<IconChevronLeft size={16} />}
              speed={30}
              onNextSlide={() => {
                setHasMoreCategories(true)
                setHasMoreTransactions(true)
                setMonth((prev) => prev + 1)
              }}
              onPreviousSlide={() => {
                setHasMoreCategories(true)
                setHasMoreTransactions(true)
                setMonth((prev) => prev - 1)
              }}
              initialSlide={month}
              draggable={false}
              styles={{
                control: {
                  background: "none",
                  border: "none",
                  color: "white",
                  boxShadow: "none",
                  "&[data-inactive]": {
                    opacity: 0,
                    cursor: "default",
                    pointerEvents: "none",
                  },
                },
              }}
              withControls
            >
              {dayjs
                .months()
                .slice(0, dayjs().month() + 1)
                .map((monthName) => (
                  <Carousel.Slide key={monthName}>
                    <Text
                      color="white"
                      weight={700}
                      transform="uppercase"
                      align="center"
                    >
                      {monthName.substring(0, 3)} 2023
                    </Text>
                  </Carousel.Slide>
                ))}
            </Carousel>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text size={12} transform="uppercase" color="white">
                Saldo previsto
              </Text>
              {
                <Text
                  size={32}
                  transform="uppercase"
                  color="white"
                  weight={700}
                  inline
                >
                  {formatCurrency(totalIncome - totalExpenses)}
                </Text>
              }
            </div>
            <Flex justify="space-between" style={{ width: "100%" }}>
              <div>
                <Text size={12} transform="uppercase" color="#D0E5E4">
                  Receitas
                </Text>
                {
                  <Text size="xl" weight="bold" color="white" inline>
                    {formatCurrency(totalIncome)}
                  </Text>
                }
              </div>

              <div>
                <Text
                  size={12}
                  transform="uppercase"
                  color="#D0E5E4"
                  align="right"
                >
                  Despesas
                </Text>
                {
                  <Text size="xl" weight="bold" color="white" inline>
                    {formatCurrency(totalExpenses)}
                  </Text>
                }
              </div>
            </Flex>
          </Stack>
        </Container>
      </div>

      <Container>
        <Text
          color="#495057"
          size="xs"
          weight="bold"
          transform="uppercase"
          mb={0}
          mt={32}
        >
          Categorias
        </Text>
        <Stack spacing="sm">
          {totalByCategory.map((category) => (
            <CategoryProgress key={category.id} {...category} />
          ))}
        </Stack>
        {hasMoreCategories && (
          <Button
            onClick={loadMoreCategories}
            size="xs"
            variant="subtle"
            loading={isLoadingMoreCategories}
            loaderProps={{ variant: "oval" }}
            mt="xs"
            compact
          >
            Ver mais
          </Button>
        )}
      </Container>

      <Container>
        <Text
          color="#495057"
          size="xs"
          weight="bold"
          transform="uppercase"
          mb={0}
          mt={32}
        >
          Últimas transações
        </Text>
        <Stack spacing="sm">
          {transactions.map((transaction) => (
            <TransactionPreview
              key={transaction.id}
              transaction={transaction}
              type={transaction.type}
            />
          ))}
        </Stack>
        {hasMoreTransactions && (
          <Button
            onClick={loadMoreTransactions}
            size="xs"
            variant="subtle"
            loading={isLoadingMoreTransactions}
            loaderProps={{ variant: "oval" }}
            mt="xs"
            compact
          >
            Ver mais
          </Button>
        )}
        <AddFloatingButton />
      </Container>
    </div>
  );
};

export default Home;
