import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import type { User } from '@supabase/supabase-js'
import { MantineProvider, Loader, Center } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/pt-br'

import { Categories } from './pages/Categories'
import { AddTransaction } from './pages/AddTransaction'
import { EditTransaction } from 'pages/EditTransaction'
import { SignIn } from './pages/SignIn'
import { ListTransaction } from './pages/ListTransaction'
import { Transactions } from './pages/Transactions'
import { Home } from './pages/Home'
import { PrivateRoute } from './components/PrivateRoute'

import { supabase } from './utils/supabase'
import { UserProvider } from './context/user'
import { BaseLayout } from 'components/BaseLayout'
import { ChangePassword } from 'pages/ChangePassword'

dayjs.locale('pt-br')
dayjs.extend(localeData)

const App = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [user, setUser] = React.useState<User | undefined>()

  React.useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user)
      setIsLoading(false)
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user)
    })
  }, [])

  if (isLoading) {
    return (
      <Center sx={{ height: '100vh' }}>
        <Loader />
      </Center>
    )
  }

  return (
    <MantineProvider
      theme={{
        colorScheme: 'light',
        primaryColor: 'teal',
        fontFamily: 'Montserrat, sans-serif',
        loader: 'bars'
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <NotificationsProvider position='top-right'>
        <UserProvider value={user}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<PrivateRoute />}>
                <Route path='/' element={<BaseLayout />}>
                  <Route path='/' element={<Home />} />
                  <Route path='/categorias' element={<Categories />} />
                  <Route path='/transacoes' element={<Transactions />} />
                </Route>
                <Route path='/adicionar/:type' element={<AddTransaction />} />
                <Route path='/transacoes/:id' element={<EditTransaction />} />

                <Route path='/trocar-senha' element={<ChangePassword />} />

                {/* <Route
                    path="/adicionar-receita"
                    element={<AddTransaction type="income" />}
                  />
                  <Route
                    path="/adicionar-investimento"
                    element={<AddTransaction type="investment" />}
                  />
                  <Route
                    path="/adicionar-emprestimo"
                    element={<AddTransaction type="loan" />}
                  /> */}
              </Route>

              <Route path='/login' element={<SignIn />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </NotificationsProvider>
    </MantineProvider>
  )
}

export default App
