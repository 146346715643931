import dayjs from "dayjs";
import { formatCurrency } from "./currency";
import { TRANSACTION_TYPE_DICTIONARY } from "./dictionaries";

// type AvailableFilters =
//   | "id"
//   | "created_at"
//   | "type"
//   | "amount"
//   | "date"
//   | "author"
//   | "category"
//   | "method"
//   | "description"
//   | "paid_by"
//   | "shared"
//   | "obs"
//   | "month"
//   | "year";

const DICTIONARY = (value: string): Record<string, string> => ({
  type: TRANSACTION_TYPE_DICTIONARY[value]?.label,
  amount: formatCurrency(Number(value)),
  date: dayjs(value).format('DD/MM/YY'),
  "category.title": value,
  method: value,
  description: value,
  shared: value === 'true' ? 'Compartilhado' : 'Pessoal',
  obs: value,
  month: dayjs.months()[Number(value) - 1],
  year: value
});

export const buildNormalizedFilterValue = (filter: string, value: string) => {
  return DICTIONARY(value)[filter];
};
