import { Card, Badge, Text } from "@mantine/core";
import { Link } from "react-router-dom";

import { TransactionType } from "../../services/categories";
import { TransactionNormalized } from "../../services/transactions";
import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";
import { useStyles } from "./TransactionPreview.styles";
import dayjs from "dayjs";
import { formatCurrency } from "utils/currency";


interface TransactionPreviewProps {
  transaction: TransactionNormalized;
  type: TransactionType;
}

const TransactionPreview = (props: TransactionPreviewProps) => {
  const { type, transaction } = props;

  const { classes } = useStyles({ type });

  return (
    <Card
      component={Link}
      to={`/transacoes/${transaction.id}`}
      shadow="xs"
      radius="md"
      px={8}
      py={12}
      className={classes.base}
      withBorder
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <div>
            <div
              style={{
                display: "flex",
                // flexDirection: "column",
                gap: 4,
                alignItems: "flex-start",
              }}
            >
              <Badge
                size="xs"
                color={TRANSACTION_TYPE_DICTIONARY[type].color}
                style={{
                  maxWidth: 100,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {transaction.category}
              </Badge>
              {transaction.subcategory && (
                <>
                  <Badge
                    size="xs"
                    variant="filled"
                    color={TRANSACTION_TYPE_DICTIONARY[type].color}
                    style={{
                      maxWidth: 120,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {transaction.subcategory}
                  </Badge>
                </>
              )}
            </div>
            <div
              style={{
                fontSize: 12,
                maxWidth: 200,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: 8,
              }}
            >
              {transaction.description}{" "}
            </div>
          </div>
        </div>
        <div>
          <Text weight="bold" size="md" align="right">
            {formatCurrency(transaction.amount)}
          </Text>
          <div>
            {/* {transaction.shared && (
              <Badge size="xs" color="yellow" variant="outline">
                Compartilhado
              </Badge>
            )} */}
            <span
              style={{ fontSize: 12, display: "block", textAlign: "right" }}
            >
              {dayjs(transaction.date).format("DD MMM")}.
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default TransactionPreview;
