import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

import { TransactionType } from "../../services/categories";

import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";

export const useStyles = createStyles((theme: MantineTheme) => {
  return {
    base: {
      // borderColor: theme.colors[color][6],
    },
  };
});
