import React from "react";
import type { FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextInput,
  NumberInput,
  Text,
  Button,
  Select,
  Switch,
  Stack,
  ActionIcon,
  Modal,
  Group,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, isNotEmpty } from "@mantine/form";
import {
  IconCheck,
  IconX,
  IconApps,
  IconCalendar,
  IconCreditCard,
  IconNotes,
  IconPencil,
  IconTrash,
} from "@tabler/icons";
import { showNotification } from "@mantine/notifications";

import { useUserContext } from "../../context/user";
import * as transactionsServices from "../../services/transactions";
import type { Transaction as TransactionInterface } from "../../services/transactions";

import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";
import { useCategories } from "hooks/use-categories";
import { useMethods } from "hooks/use-methods";
import { currencyDecimalFormatter, currencyParser } from "utils/currency";
import { useStyles } from "./Transaction.styles";
import { Nav } from "components/Nav";
import dayjs from "dayjs";

interface FormValues
  extends Omit<
    TransactionInterface,
    "id" | "created_at" | "author" | "paid_by" | "date" | "type"
  > {
  date: any;
}

interface TransactionProps extends Partial<TransactionInterface> {}

export function Transaction(props: TransactionProps) {
  const {
    type = "expense",
    amount,
    category,
    date,
    description,
    method,
    shared,
    obs,
    id,
  } = props;
  const [error, setError] = React.useState<string | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const user = useUserContext();

  const categories = useCategories(type);
  const methods = useMethods(type, user);

  const navigate = useNavigate();
  const { classes } = useStyles({ type });

  const form = useForm<FormValues>({
    initialValues: {
      amount: Number(amount) || 0,
      category: category || "",
      date: date ? new Date(date) : new Date(),
      description: description || "",
      method: method || null,
      shared: shared || false,
      obs: obs || "",
    },
    validate: {
      amount: (value) => Number(value) <= 0,
      category: (value) => isNotEmpty()(value),
      date: (value) => isNotEmpty()(value),
      method: (value) => type !== "income" && isNotEmpty()(value),
    },
  });

  const selectClasses = {
    item: classes.selectItem,
    separator: classes.selectSeparator,
    separatorLabel: classes.selectSeparatorLabel,
  };

  const inputClasses = {
    label: classes.label,
  };

  const numberInputClasses = {
    label: classes.numberLabel,
    input: classes.numberInput,
  };

  const switchClasses = {
    root: classes.switchRoot,
    body: classes.switchBody,
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(undefined);

    if (!user) {
      throw new Error("Ops!");
    }

    const action = id
      ? (data: FormValues) =>
          transactionsServices.editTransaction({ ...data, id })
      : transactionsServices.addTransaction;

    try {
      const { error: newError } = await action({
        ...form.values,
        amount: form.values.amount / 100,
        type,
        author: user.id,
        paid_by: user.id,
      });

      if (!newError) {
        showNotification({
          title: "Eba!",
          message: `Transação ${!id ? "cadastrada" : "editada"} com sucesso`,
          color: "green",
          icon: <IconCheck size={18} />,
        });
        navigate("/");
      } else {
        setError(newError?.message);
        showNotification({
          title: "Ops!",
          message: newError.message || "Ops! Ocorreu um erro!",
          color: "red",
          icon: <IconX size={18} />,
        });
      }
    } catch (err: unknown) {
      const errorMessage = (err as Error).message;

      showNotification({
        title: "Ops!",
        message: errorMessage || "Ops! Ocorreu um erro!",
        color: "red",
        icon: <IconX size={18} />,
      });

      setError(errorMessage || "Ops! Ocorreu um erro!");
    }
  };

  const handleDelete = async () => {
    if (!id) {
      return;
    }

    try {
      const { error: newError } = await transactionsServices.deleteTransaction(
        id
      );

      if (!newError) {
        showNotification({
          title: "Pronto!",
          message: `Transação deletada com sucesso`,
          color: "green",
          icon: <IconCheck size={18} />,
        });
        navigate("/");
      } else {
        setError(newError?.message);
        showNotification({
          title: "Ops!",
          message: newError.message || "Ops! Ocorreu um erro!",
          color: "red",
          icon: <IconX size={18} />,
        });
      }
    } catch (err: unknown) {
      const errorMessage = (err as Error).message;

      showNotification({
        title: "Ops!",
        message: errorMessage || "Ops! Ocorreu um erro!",
        color: "red",
        icon: <IconX size={18} />,
      });

      setError(errorMessage || "Ops! Ocorreu um erro!");
    }
  };

  return (
    <>
      {id && (
        <Modal
          opened={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title="Tem certeza que deseja excluir a transação?"
          centered
        >
          <Group position="right">
            <Button
              variant="outline"
              color="red"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancelar
            </Button>
            <Button onClick={handleDelete}>Confirmar</Button>
          </Group>
        </Modal>
      )}
      <Nav type={type}>
        {!id ? "Adicionar" : "Editar"} {TRANSACTION_TYPE_DICTIONARY[type].label}
      </Nav>
      <form onSubmit={handleSubmit}>
        <div className={classes.background}>
          <NumberInput
            label="Valor"
            variant="unstyled"
            min={0}
            precision={2}
            parser={currencyParser}
            formatter={currencyDecimalFormatter}
            removeTrailingZeros={form.isTouched("amount")}
            hideControls
            size="lg"
            inputMode="tel"
            classNames={numberInputClasses}
            autoFocus
            {...form.getInputProps("amount")}
          />
          {id && (
            <ActionIcon
              onClick={() => setIsDeleteModalOpen(true)}
              variant="transparent"
            >
              <IconTrash color="white" />
            </ActionIcon>
          )}
        </div>

        <Stack px={16}>
          {type === "expense" && (
            <Switch
              size="sm"
              label="Despesa compartilhada"
              labelPosition="left"
              classNames={{ ...inputClasses, ...switchClasses }}
              {...form.getInputProps("shared")}
              checked={form.getInputProps("shared").value}
            />
          )}

          {categories && (
            <Select
              icon={<IconApps size={16} />}
              data={categories}
              searchable
              nothingFound="Nenhuma categoria encontrada"
              size="sm"
              radius="md"
              label="Categoria"
              variant="filled"
              classNames={{ ...selectClasses, ...inputClasses }}
              clearable
              error
              {...form.getInputProps("category")}
            />
          )}

          <DatePicker
            icon={<IconCalendar size={16} />}
            dropdownType="modal"
            defaultValue={new Date()}
            maxDate={new Date()}
            size="sm"
            radius="md"
            label="Data da transação"
            variant="filled"
            classNames={inputClasses}
            locale="pt-BR"
            {...form.getInputProps("date")}
          />

          {methods.length > 0 && (
            <Select
              icon={<IconCreditCard size={16} />}
              data={methods?.map((method) => ({
                value: method.id,
                label: method.name,
              }))}
              size="sm"
              radius="md"
              label="Forma de pagamento"
              variant="filled"
              clearable
              classNames={{ ...selectClasses, ...inputClasses }}
              {...form.getInputProps("method")}
            />
          )}

          <TextInput
            icon={<IconNotes size={16} />}
            size="sm"
            radius="md"
            label="Descrição"
            variant="filled"
            classNames={inputClasses}
            {...form.getInputProps("description")}
          />

          <TextInput
            icon={<IconPencil size={16} />}
            size="sm"
            radius="md"
            label="Obs"
            variant="filled"
            classNames={inputClasses}
            {...form.getInputProps("obs")}
          />

          {/* verificar isValid - adicionar validate */}
          <Button
            fullWidth
            radius="md"
            size="md"
            my={16}
            type="submit"
            disabled={!form.isValid()}
            variant="gradient"
          >
            {!id ? "Adicionar" : "Salvar"}
          </Button>
        </Stack>

        {error && (
          <Text color="red" size="xs" align="center" mt="md">
            {error}
          </Text>
        )}
      </form>
    </>
  );
}

export default Transaction;
