import { supabase } from "../utils/supabase";

export type TransactionType = "income" | "expense" | "investment" | "loan";

export interface Method {
  id: string;
  created_at: string | null;
  name: string;
  owner: string | null;
}

export const getMethodsByOwner = async (
  owner: Method["owner"]
): Promise<Method[] | null> => {
  if (!owner) {
    throw new Error("You need to select an owner");
  }

  const { data } = await supabase
    .from("methods")
    .select("*")
    .eq("owner", owner);

  return data;
};

// export const getCategories = async (): Promise<Category[] | null> => {
//   const { data } = await supabase
//     .from("categories")
//     .select("*")
//     .is("parent_id", null);

//   return data;
// };

// export const getCategoriesByType = async (
//   type: TransactionType
// ): Promise<Category[] | null> => {
//   if (!type) {
//     throw new Error("You need to select a transaction type");
//   }

//   const { data } = await supabase
//     .from("categories")
//     .select("*")
//     .is("parent_id", null)
//     .filter("type", "eq", type);

//   return data;
// };

// export const addCategory = async (category: Category) => {
//   return await supabase.from("categories").insert(category);
// };

// export const updateCategory = async ({
//   id,
//   ...category
// }: Partial<Category>) => {
//   return await supabase.from("categories").update(category).eq("id", id);
// };

// export const deleteCategory = async (id: Category["id"]) => {
//   return await supabase.from("categories").delete().eq("id", id);
// };
