import { Badge, ActionIcon, Flex } from "@mantine/core";
import { IconX } from "@tabler/icons";
import { buildNormalizedFilterValue } from "utils/filters";


interface UsedFiltersProps {
  filters?: Record<string, string>;
  onRemove?: (filter: string) => void;
}

const UsedFilters = (props: UsedFiltersProps) => {
  const { filters, onRemove } = props;

  if (!filters) {
    return <span />;
  }

  return (
    <Flex gap="xs" wrap="wrap">
      {Object.entries(filters).map(([filter, value]) => {
        return (
          <Badge
            key={filter}
            variant="filled"
            size="sm"
            radius="sm"
            rightSection={
              onRemove && (
                <ActionIcon
                  size="xs"
                  color="teal"
                  radius="xl"
                  variant="filled"
                  onClick={() => onRemove?.(`filters[${filter}]`)}
                >
                  <IconX size={10} />
                </ActionIcon>
              )
            }
          >
            {buildNormalizedFilterValue(filter, value)}
          </Badge>
        );
      })}
    </Flex>
  );
};

export default UsedFilters;
