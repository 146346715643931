import React from "react";

import { DatePicker as DatePickerBase } from "@mantine/dates";
import type { DatePickerProps as DatePickerBaseProps } from "@mantine/dates";

import { useController } from "react-hook-form";
import type { UseControllerProps } from "react-hook-form";

export interface DatePickerProps
  extends Omit<DatePickerBaseProps, "defaultValue" | "name">,
    UseControllerProps {}

function DatePicker(props: DatePickerProps, ref: any) {
  const { field } = useController(props);

  return <DatePickerBase {...props} {...field} ref={ref} />;
}

export default React.forwardRef(DatePicker);
