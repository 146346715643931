import { Outlet } from "react-router-dom";

import { Footer } from "components/Footer";

const BaseLayout = () => {
  return (
    <div style={{ paddingBottom: 70 }}>
      <Outlet />
      <Footer />
    </div>
  );
};

export default BaseLayout;
