import qs from "qs";
import { useFormContext } from "react-hook-form";
import { Button, Drawer, Stack, Text } from "@mantine/core";
import { IconCalendar } from "@tabler/icons";

import { Select } from "components/Select";
import { SegmentedControl } from "components/SegmentedControl";
import { DatePicker } from "components/DatePicker";
import filterFalsyValue from "utils/filter-falsy-value";
import { useCategories } from "hooks/use-categories";
import { useMethods } from "hooks/use-methods";
import { useUserContext } from "context/user";

export type FormValues = {
  type: string;
  category: string;
  date: string;
  method: string;
  shared: string;
  month: string;
};

interface FiltersProps {
  isOpen: boolean;
  onFilter: (data: string) => void;
  onClose: () => void;
}

const Filters = (props: FiltersProps) => {
  const { isOpen, onFilter, onClose } = props;

  const categories = useCategories("expense");
  const user = useUserContext();
  const methods = useMethods("expense", user);
  const { control, handleSubmit } = useFormContext();

  const onSubmit = async (data: Record<string, any>) => {
    if (!user) {
      throw new Error("Ops!");
    }

    const filters: any = filterFalsyValue(data);
    if (filters.category?.title) {
      const { category, ...rest } = filters;

      const label = categories.find(
        (item) => item.value === filters.category?.title
      )?.label;

      onFilter(
        qs.stringify({
          filters: { ...rest, "category.title": label },
        })
      );
    } else {
      onFilter(qs.stringify({ filters }));
    }
    onClose();
  };

  return (
    <Drawer
      opened={isOpen}
      onClose={onClose}
      padding={16}
      position="bottom"
      size="auto"
    >
      <Text weight="bold" mb={32}>
        Filtros
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="xl">
          <Select
            label="Tipo"
            data={[
              { label: "Todos", value: "undefined" },
              { label: "Receitas", value: "income" },
              { label: "Despesas", value: "expense" },
              { label: "Investimentos", value: "investment" },
              { label: "Empréstimos", value: "loan" },
            ]}
            defaultValue="undefined"
            size="sm"
            radius="md"
            variant="filled"
            name="type"
            control={control}
          />

          {categories && (
            <Select
              data={categories}
              searchable
              nothingFound="Nenhuma categoria encontrada"
              size="sm"
              radius="md"
              label="Categoria"
              variant="filled"
              clearable
              name="category.title"
              control={control}
            />
          )}

          <DatePicker
            icon={<IconCalendar size={16} />}
            dropdownType="modal"
            maxDate={new Date()}
            size="sm"
            radius="md"
            label="Data da transação"
            variant="filled"
            locale="pt-BR"
            inputFormat="YYYY/MM/DD"
            name="date"
            control={control}
          />

          <Select
            label="Método de pagamento"
            data={methods?.map((method) => ({
              value: method.id,
              label: method.name,
            }))}
            size="sm"
            radius="md"
            variant="filled"
            name="method"
            control={control}
          />

          <div>
            <Text size="sm">Compartilhamento</Text>
            <SegmentedControl
              data={[
                { label: "Todas", value: "undefined" },
                { label: "Compartilhadas", value: "true" },
                { label: "Pessoais", value: "false" },
              ]}
              size="sm"
              radius="md"
              fullWidth
              name="shared"
              control={control}
            />
          </div>

          <Button type="submit" mt={16} name="filter" fullWidth>
            Filtrar
          </Button>
        </Stack>
      </form>
    </Drawer>
  );
};

export default Filters;
