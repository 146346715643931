import React from 'react';

import { SegmentedControl as SegmentedControlBase } from '@mantine/core';
import type { SegmentedControlProps as SegmentedControlBaseProps } from '@mantine/core';

import { useController } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';

export interface SegmentedControlProps
  extends Omit<SegmentedControlBaseProps, 'defaultValue' | 'name'>,
    UseControllerProps {}

function SegmentedControl(props: SegmentedControlProps, ref: any) {
  const { field } = useController(props);

  return <SegmentedControlBase {...props} {...field} ref={ref} />;
}

export default React.forwardRef(SegmentedControl);
