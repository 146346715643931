import { supabase } from "../utils/supabase";

export type TransactionType = "income" | "expense" | "investment" | "loan";

export interface Category {
  id: string;
  created_at?: string;
  type: TransactionType;
  title: string;
  color: string;
  budget: number | null;
  parent_id?: string | null;
}

interface SubCategory extends Omit<Category, "parent_id"> {
  parent_id: {
    title: string;
  };
}

// type MoviesResponse = Awaited<ReturnType<typeof addCategory>>
//  export type MoviesResponseSuccess = MoviesResponse['data']
//  export type MoviesResponseError = MoviesResponse['error']

export const getSubCategories = async (
  parent: Category["parent_id"]
): Promise<Category[] | null> => {
  if (!parent) {
    throw new Error("You need to select a parent category");
  }

  const { data } = await supabase
    .from("categories")
    .select("*")
    .eq("parent_id", parent);

  return data;
};

export const getSubCategoriesByType = async (
  type: Category["type"]
): Promise<SubCategory[] | null> => {
  if (!type) {
    throw new Error("You need to select a type");
  }

  const { data } = await supabase
    .from("categories")
    .select("*, parent_id(title)")
    .not("parent_id", "is", null)
    .eq("type", type);

  return data;
};

export const getCategories = async (): Promise<Category[] | null> => {
  const { data } = await supabase
    .from("categories")
    .select("*")
    .is("parent_id", null);

  return data;
};

export const getCategoriesByType = async (
  type: TransactionType
): Promise<Category[] | null> => {
  if (!type) {
    throw new Error("You need to select a transaction type");
  }

  const { data } = await supabase
    .from("categories")
    .select("*")
    .is("parent_id", null)
    .filter("type", "eq", type);

  return data;
};

export const addCategory = async (category: Omit<Category, "id">) => {
  return await supabase.from("categories").insert(category);
};

export const updateCategory = async ({
  id,
  ...category
}: Partial<Category>) => {
  return await supabase.from("categories").update(category).eq("id", id);
};

export const deleteCategory = async (id: Category["id"]) => {
  return await supabase.from("categories").delete().eq("id", id);
};
