import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

import { TransactionType } from "../../services/categories";
import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";

export const useStyles = createStyles(
  (theme: MantineTheme, params: { type: TransactionType }) => {
    const color = TRANSACTION_TYPE_DICTIONARY[params.type].color;

    return {
      background: {
        background: theme.colors[color][7],
        padding: 16,
        marginBottom: 16,
        display: 'flex'
      },
      switchRoot: {
        width: "100%",
      },
      switchBody: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      label: {
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: theme.fontSizes.xs,
      },
      numberLabel: {
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: theme.fontSizes.xs,
        color: theme.colors.gray[0],
      },
      numberInput: {
        color: theme.colors.gray[0],
        fontSize: 48,
        fontWeight: "bold",
      },
      selectItem: {
        fontSize: theme.fontSizes.xs,
        padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
      },
      selectSeparator: {
        padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
        marginTop: theme.spacing.sm,
      },
      selectSeparatorLabel: {
        color: theme.colors.dark[9],
        fontSize: theme.fontSizes.sm,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    };
  }
);
