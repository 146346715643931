import { Card, Text, Progress } from "@mantine/core";
import { Link } from "react-router-dom";

import type { TotalByCategory } from "../../services/transactions";
import { useStyles } from "./CategoryProgress.styles";
import { formatCurrency } from "utils/currency";

interface CategoryProgressProps extends TotalByCategory {}

const CategoryProgress = (props: CategoryProgressProps) => {
  const { category, budget, total, id } = props;

  const { classes } = useStyles();

  if (!total || !budget) {
    return null;
  }

  const value = (total / budget) * 100;

  return (
    <Card
      component={Link}
      to={`/categorias/${id}`}
      shadow="xs"
      radius="md"
      px={8}
      py={12}
      className={classes.base}
      withBorder
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text
          size="xs"
          transform="uppercase"
          // color="dimmed"
          weight={700}
        >
          {category}
        </Text>
        <div>
          <Text size="xs" weight={700} align="right" span>
            {formatCurrency(total)}
          </Text>
          <Text size={10} weight={500} color="dimmed" align="right" span>
            {" "}
            / {formatCurrency(budget)}
          </Text>
        </div>
      </div>
      <Progress
        value={value}
        mt="xs"
        size="xs"
        radius="xl"
        color={total <= budget ? "green" : "red"}
      />
    </Card>
  );
};

export default CategoryProgress;
