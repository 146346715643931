import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

import { TransactionType } from "../../services/categories";

import { TRANSACTION_TYPE_DICTIONARY } from "../../utils/dictionaries";

export const useStyles = createStyles(
  (theme: MantineTheme, params: { type: TransactionType }) => {
    const color = TRANSACTION_TYPE_DICTIONARY[params.type].color;

    return {
      base: {
        all: "unset",
        position: "sticky",
        top: 0,
        zIndex: 1,
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 16,
        background: theme.colors[color][7],

        button: {
          color: "white",
          flexShrink: 0,
        },
      },
    };
  }
);
