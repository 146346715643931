import { TransactionNormalized } from "services/transactions";
import { Category } from "../services/categories";

export const groupSubCategories = (
  categories: Category[],
  subCategories: Category[]
) => {
  return categories.map((category: Category) => {
    return subCategories.map((sub) => ({
      label: sub.title,
      value: sub.id,
      group: category.title,
    }));
  });
};

type GroupedSubCategories = {
  [title: string]: SubCategory[];
};

interface SubCategory extends Omit<Category, "parent_id"> {
  parent_id: {
    title: string;
  };
}

export function groupSubCategoriesByCategoryTitle(
  array: SubCategory[]
): GroupedSubCategories {
  return array.reduce((acc: GroupedSubCategories, item) => {
    const { title } = item["parent_id"];

    if (!acc[title]) {
      acc[title] = [];
    }

    acc[title].push(item);

    return acc;
  }, {});
}

export type SelectGroupedOption = {
  label: string;
  value: string;
  group: string;
};

export const getOptionsBySubCategories = (array: SubCategory[]) => {
  return array.reduce((acc: SelectGroupedOption[], item) => {
    const { title } = item["parent_id"];

    return [...acc, { label: item.title, value: item.id, group: title }];
  }, []);
};

interface By
  extends Pick<
    TransactionNormalized,
    "author" | "type" | "date" | "category"
  > {}

type GroupedTransactions = {
  [key: string]: TransactionNormalized[];
};

export const groupBy = (data: TransactionNormalized[], by: keyof By) => {
  return data.reduce((acc: GroupedTransactions, item) => {
    if (!acc[item[by]]) {
      acc[item[by]] = [];
    }

    acc[item[by]] = [...acc[item[by]], item];

    return acc;
  }, {});
};

// export const totalAmountByCategory = (data: TransactionNormalized[]) =>
//   data.reduce((acc: { [key: string]: number }, item) => {
//     const { category } = item;

//     if (!acc[category]) {
//       return { ...acc, [category]: item.amount };
//     }

//     const totalCategory = acc[category];

//     return {
//       ...acc,
//       [category]: totalCategory + item.amount,
//     };
//   }, {});

export const totalAmountBy = (data: TransactionNormalized[], by: keyof By) => {
  return data.reduce((acc: { [key: string]: number }, item) => {
    const key = item[by];

    if (!acc[key]) {
      return { ...acc, [key]: item.amount };
    }

    const totalCategory = acc[key];

    return {
      ...acc,
      [key]: totalCategory + item.amount,
    };
  }, {});
};
