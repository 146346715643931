import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  base: {
    padding: 8,
    width: "100%",
    position: "fixed",
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    background: "white",
    borderTop: "1px solid #e1e1e1",
  },
}));
