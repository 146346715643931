import React from "react";
import type { User } from "@supabase/supabase-js";

import * as methodsServices from "../services/methods";
import type { TransactionType } from "../services/categories";
import type { Method } from "../services/methods";


export const useMethods = (type: TransactionType, user?: User) => {
  const [methods, setMethods] = React.useState<Method[]>([]);

  React.useEffect(() => {
    const hasPaymentMethod = type !== "income";

    if (!hasPaymentMethod || !user) {
      return;
    }

    const getMethods = async () => {
      const data = await methodsServices.getMethodsByOwner(user.id);

      if (data?.length) {
        setMethods(data);
      }
    };

    getMethods();
  }, [type, user]);

  return methods;
};
