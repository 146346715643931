type Dictionary = {
  [key: string]: {
    label: string;
    color: string;
  };
};

export const TRANSACTION_TYPE_DICTIONARY: Dictionary = {
  income: {
    label: "Receita",
    color: "green",
  },
  expense: {
    label: "Despesa",
    color: "red",
  },
  investment: {
    label: "Investimento",
    color: "blue",
  },
  loan: {
    label: "Empréstimo",
    color: "orange",
  },
};
