import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../../context/user";


const PrivateRoute = () => {
  const user = useUserContext();

  return user ? <Outlet /> : <Navigate to="/login" />;
}


export default PrivateRoute;
