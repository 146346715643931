import React from "react";
import { useParams } from "react-router-dom";
import { Center, Loader } from "@mantine/core";

import { Transaction } from "components/Transaction";

import * as transactionsServices from "../../services/transactions";
import type { Transaction as TransactionInterface } from "../../services/transactions";

export function EditTransaction() {
  const [transaction, setTransaction] = React.useState<TransactionInterface>();
  const [isLoading, setIsLoading] = React.useState(true);

  const { id } = useParams<{ id: TransactionInterface["id"] }>();

  React.useEffect(() => {
    const getTransaction = async () => {
      if (!id) {
        return;
      }

      const data = await transactionsServices.getTransaction(id);

      if (data) {
        setTransaction(data);
      }
    };

    getTransaction().then(() => {
      setIsLoading(false);
    });
  }, [id]);

  if (isLoading) {
    return (
      <Center sx={{ height: "100vh" }}>
        <Loader />
      </Center>
    );
  }

  return <Transaction {...transaction} />;
}

export default EditTransaction;
