import React from "react";
import type { FormEvent } from "react";
import { Container } from "@mantine/core";

import * as services from "../../services/categories";
import type { Category, TransactionType } from "../../services/categories";

export function SignIn() {
  const [error, setError] = React.useState<string>();
  const [categories, setCategories] = React.useState<Category[] | null>();
  const [subCategories, setSubCategories] = React.useState<Category[] | null>();
  const [transactionType, setTransactionType] =
    React.useState<TransactionType>();
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string>();

  const typeRef = React.useRef<HTMLSelectElement | null>(null);
  const titleRef = React.useRef<HTMLInputElement | null>(null);
  const colorRef = React.useRef<HTMLInputElement | null>(null);
  const budgetRef = React.useRef<HTMLInputElement | null>(null);
  const parentRef = React.useRef<HTMLSelectElement | null>(null);

  const type = typeRef?.current?.value as TransactionType;
  const title = titleRef?.current?.value || null;
  const color = colorRef?.current?.value || null;
  const budget = budgetRef?.current?.value || null;
  const parent = parentRef?.current?.value || null;

  const isValid = Boolean(type && title && color);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!title || !color) {
      return setError("Por favor, preencha todos os campos");
    }

    const { error: newError } = await services.addCategory({
      type,
      title,
      color,
      budget: budget ? Number(budget) : null,
      parent_id: parent,
    });

    setError(newError?.message);
  };

  React.useEffect(() => {
    if (!transactionType) {
      return;
    }

    const getCategories = async () => {
      const data = await services.getCategoriesByType(transactionType);

      if (data?.length) {
        setCategories(data);
      }
    };

    getCategories();
  }, [transactionType]);

  React.useEffect(() => {
    if (!selectedCategoryId) {
      return;
    }

    const getSubCategories = async () => {
      const data = await services.getSubCategories(selectedCategoryId);

      if (data?.length) {
        setSubCategories(data);
      }
    };

    getSubCategories();
  }, [selectedCategoryId]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label htmlFor="input-type">Tipo</label>
        <select
          name="input-type"
          ref={typeRef}
          onChange={(event) =>
            setTransactionType(event.target.value as TransactionType)
          }
        >
          <option disabled selected value={undefined}>
            {" "}
            -- select an option --{" "}
          </option>
          <option value="income">Receita</option>
          <option value="expense">Despesa</option>
          <option value="investment">Investimento</option>
          <option value="loan">Empréstimo</option>
        </select>

        {categories && (
          <>
            <label htmlFor="input-parent">Categoria principal</label>
            <select
              name="input-type"
              ref={parentRef}
              onChange={(event) => setSelectedCategoryId(event.target.value)}
            >
              <option disabled selected value={undefined}>
                {" "}
                -- select an option --{" "}
              </option>
              {categories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </>
        )}

        {subCategories && (
          <>
            <label htmlFor="input-parent">Categoria principal</label>
            <select name="input-type" ref={parentRef}>
              <option disabled selected value={undefined}>
                {" "}
                -- select an option --{" "}
              </option>
              {subCategories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </>
        )}

        <label htmlFor="input-title">Nome</label>
        <input id="input-title" type="text" ref={titleRef} />

        <label htmlFor="input-color">Cor</label>
        <input id="input-color" type="text" ref={colorRef} />

        <label htmlFor="input-budget">Orçamento estimado</label>
        <input id="input-budget" type="text" ref={budgetRef} />

        <button type="submit" disabled={!isValid}>
          Adicionar categoria
        </button>
      </form>

      {error && <h1>{error}</h1>}
    </Container>
  );
}
export default SignIn;
