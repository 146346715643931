import { createStyles } from "@mantine/core";
import type { MantineTheme } from "@mantine/core";

import { TransactionType } from "../../services/categories";

export const useStyles = createStyles((theme: MantineTheme) => {
  return {
    base: {
      all: "unset",
      position: "sticky",
      top: 0,
      zIndex: 1,
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 16,

      button: {
        color: "white",
        flexShrink: 0,
      },
    },
  };
});
