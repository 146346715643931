import React from "react";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { FormProvider, useForm } from "react-hook-form";
import {
  Container,
  Text,
  Stack,
  Flex,
  Center,
  Loader,
  ActionIcon,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import {
  IconChevronRight,
  IconChevronLeft,
  IconFilter,
} from "@tabler/icons";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { AddFloatingButton } from "components/AddFloatingButton";
import { Filters } from "components/Filters";
import { Nav } from "components/Nav";
import { formatCurrency } from "utils/currency";

import type { TransactionNormalized } from "../../services/transactions";
import * as transactionsServices from "../../services/transactions";

import { TransactionPreview } from "components/TransactionPreview";
import { UsedFilters } from "components/UsedFilters";
import { calculateTotal } from "utils/calculate-total";

dayjs.locale("pt-br");

type FormValues = {
  type: string;
  category: string;
  date: string;
  method: string;
  shared: string;
  month: string;
};

const Home = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [isFiltersOpen, setIsFiltersOpen] = React.useState(false);
  const [transactions, setTransactions] = React.useState<
    TransactionNormalized[]
  >([]);

  const total = React.useMemo(
    () => (transactions.length ? calculateTotal(transactions) : 0),
    [transactions]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const filters = React.useMemo<{ filters: FormValues }>(
    () => qs.parse(searchParams.toString()) as { filters: FormValues },
    [searchParams]
  ).filters;

  const form = useForm<FormValues>({
    defaultValues: filters,
  });

  React.useEffect(() => {
    setIsLoading(true);

    const getTransactions = async () => {
      const data = await transactionsServices.getTransactionsFinal({
        filters: {
          month: (month + 1).toString(),
          ...(filters as Record<string, string>),
        },
      });
      if (data) {
        setTransactions(data);
      }
    };

    getTransactions();
    setIsLoading(false);
  }, [filters, month]);

  React.useEffect(() => {
    form.reset({ ...filters });
  }, [filters, form]);

  if (isLoading) {
    return (
      <Center sx={{ height: "100vh" }}>
        <Loader />
      </Center>
    );
  }

  return (
    <div>
      <FormProvider {...form}>
        <Filters
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
          onFilter={(data) => {
            setSearchParams(data);
          }}
        />
      </FormProvider>

      <Stack mt={32}>
        <Container>
          <Center>
            <Carousel
              sx={{ maxWidth: 200 }}
              nextControlIcon={<IconChevronRight size={16} />}
              previousControlIcon={<IconChevronLeft size={16} />}
              speed={30}
              onNextSlide={() => {
                setSearchParams({ filters: "" });
                setMonth((prev) => prev + 1);
              }}
              onPreviousSlide={() => {
                setSearchParams({ filters: "" });
                setMonth((prev) => prev - 1);
              }}
              initialSlide={month}
              draggable={false}
              styles={{
                control: {
                  background: "none",
                  border: "none",
                  color: "black",
                  boxShadow: "none",
                  "&[data-inactive]": {
                    opacity: 0,
                    cursor: "default",
                    pointerEvents: "none",
                  },
                },
              }}
              withControls
            >
              {dayjs
                .months()
                .slice(0, dayjs().month() + 1)
                .map((monthName) => (
                  <Carousel.Slide key={monthName}>
                    <Text
                      color="black"
                      weight={700}
                      transform="uppercase"
                      align="center"
                    >
                      {monthName.substring(0, 3)} 2023
                    </Text>
                  </Carousel.Slide>
                ))}
            </Carousel>
          </Center>
        </Container>

        <Container mt={16}>
          <Text size={12} transform="uppercase" align="center">
            Total
          </Text>
          <Text weight="bold" size="xl" color={total >= 0 ? "green" : "red"}>
            {formatCurrency(total)}
          </Text>
        </Container>

        <Container sx={{ width: "100%" }} mb={16}>
          <Flex justify="space-between" align="center" sx={{ width: "100%" }}>
            {
              <UsedFilters
                filters={filters as Record<string, string>}
                onRemove={(filter) =>
                  setSearchParams((prev) => {
                    prev.delete(filter);
                    return prev;
                  })
                }
              />
            }
            <ActionIcon onClick={() => setIsFiltersOpen(true)}>
              <IconFilter />
            </ActionIcon>
          </Flex>
        </Container>

        <Container>
          <Stack spacing="sm">
            {transactions.map((transaction) => (
              <TransactionPreview
                key={transaction.id}
                transaction={transaction}
                type={transaction.type}
              />
            ))}
          </Stack>
        </Container>
      </Stack>
      <AddFloatingButton />
    </div>
  );
};

export default Home;
