import type { ReactNode } from "react";
import { ActionIcon, Text } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { useNavigate } from "react-router-dom";

import { TransactionType } from "../../services/categories";
import { useStyles } from "./Nav.styles";

interface NavProps {
  children: ReactNode;
  type: TransactionType;
}

const Nav = (props: NavProps) => {
  const navigate = useNavigate();
  const { classes } = useStyles({ type: props.type });

  return (
    <div className={classes.base}>
      <ActionIcon onClick={() => navigate(-1)} variant="transparent">
        <IconArrowLeft />
      </ActionIcon>
      <Text weight="bold">{props.children}</Text>
      <span />
    </div>
  );
};

export default Nav;
