export const currencyParser = (value: string = "") =>
  value
    ?.replace(/^0+/g, "")
    .replace(/[^0-9]+/g, "")
    .replace(/,/g, "")
    .replace(/R\$/g, "");

export const currencyDecimalFormatter = (value: string = "") =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(parseFloat(value.replace(".", "")) / 100);

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
